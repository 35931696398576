<template>
  <div>
    <van-cell-group v-for="(item,index) in myCustomerServiceList" :key="index" :title="'售后单'+(myCustomerServiceList.length-index)">
      <van-cell title="联系人" :value="item.contactName"></van-cell>
      <van-cell title="电话" :value="item.phone"></van-cell>
      <van-cell title="公司名称" :value="item.companyName"></van-cell>
      <van-cell title="型号" :value="item.product"></van-cell>
      <van-cell title="备注" :value="item.remark"></van-cell>

      <van-cell title="处理状态">
        <van-tag v-if="item.method" type="success">{{item.method}}</van-tag>
        <van-tag v-else type="danger">处理中</van-tag>
      </van-cell>
    </van-cell-group>
    <van-empty v-if="myCustomerServiceList.length==0" description="无登记信息"></van-empty>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
Vue.use(Vant);

export default {
  data() {
    return { myCustomerServiceList: [] };
  },
  mounted() {
    let that = this;
    that.axios
      .post("CustomerService/GetMyCustomerService")
      .then(function (response) {
        that.myCustomerServiceList = response.data.data;
      });
  },
};
</script>

<style>
</style>